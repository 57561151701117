import styled from "styled-components";
import JSConfetti from "js-confetti";

const jsConfetti = new JSConfetti();

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Border = styled.div`
  border: 10px solid black;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &:hover {
    background-color: yellow;
  }
`;
const Line = styled.div`
  font-size: 42px;
  user-select: none;
  font-family: "Press Start 2P", cursive;
`;

function App() {
  return (
    <Container>
      <Border
        onClick={() => {
          console.log("VIVE MODEZ");
          jsConfetti.addConfetti();
        }}
      >
        <Line>VIVE</Line>
        <Line>MODEZ</Line>
      </Border>
    </Container>
  );
}

export default App;
